function showPopup(popupId) {
    if (!sessionStorage.getItem(popupId, true)) {
        var options = {
            "method": 'GET',
            "data": null,
            "modifiers": ['popup']
        };
        Overlay.load('/popup?popup=' + encodeURIComponent(window.location.pathname), options);
        sessionStorage.setItem(popupId, true);
    }
}

const script = document.querySelector("#popup-script");
if (script) {
    setTimeout(function() {showPopup(script.dataset.id)}, script.dataset.time);
}
